import invisiblematerial from "../../assets/InvisibleGrill/Products/invisiblematerial.webp";
import InvisibleWire from "../../assets/InvisibleGrill/Products/InvisibleWire.webp";
import Hooks from "../../assets/InvisibleGrill/Products/Hooks.webp";
import turnbuckle from "../../assets/InvisibleGrill/Products/turnbuckle.webp";
import Hooks2 from "../../assets/InvisibleGrill/Products/Hooks2.webp";
import BulletHook from "../../assets/InvisibleGrill/Products/BulletHook.webp";

// import guaranty from "../../src/assets/About/guaranty.png";
const InvisibleData = [
  {
    img: invisiblematerial,
    title: "Invisible Grill Raw Material",
    desc: "Premium stainless steel wire, highly durable and corrosion-resistant, designed for seamless and discreet safety installations. Ideal for modern architecture, providing both security and an unobstructed view. Perfect for residential and commercial applications.",
    alt: "Invisible Grill Raw Material",
    GradeField: "304/316",
    Technique: "customized",
    Material: "Stainless Steel",
    ProductType: "Balcony Grill",
    UsageApplication: "Home",
    GrillDesign: "Modern",
    WireDiameter: "2.5 mm",
    SurfaceFinish: "Nylon Coated",
    WireSpacing: "2,3,4",
    Color: "brown, black, white",
    Feature: "Rust Proof, Weather Proof, Durable",
  },
  {
    img: InvisibleWire,
    title: "Stainless Steel Invisible Grill Wire Roll",
    desc: "High-quality stainless steel wire, engineered for invisible grills, offering exceptional strength and durability. Ideal for maintaining unobstructed views while ensuring maximum safety and security. Perfect for both residential and commercial applications.",
    alt: "Stainless Steel Invisible Grill Wire Roll",
    GradeField: "304/316",
    Technique: "customized",
    Material: "Stainless Steel",
    ProductType: "Invisible Grill Wire Roll",
    UsageApplication: "Used To Safeguard Homes",
    GrillDesign: "Modern",
    WireDiameter: "2.5 mm",
    SurfaceFinish: "Powder Coated",
    WireSpacing: "2,3,4",
    Color: "brown, black, white",
    Feature: "Rust Proof",
  },
  {
    img: Hooks,
    title: "Snap Hook",
    desc: "stainless steel snap hook designed for secure attachment of invisible grill wires, ensuring stability and reliability. Ideal for seamless installation and durability in both residential and commercial settings. Perfect for enhancing the safety and functionality of your invisible grill system.",
    alt: "Snap Hook",
    GradeField: "304/316",
    Technique: "customized",
    Material: "Stainless Steel",
    ProductType: "Invisible Grill Wire Roll Hook",
    UsageApplication: "Construction Industry",
    GrillDesign: "Modern",
    WireDiameter: "3 mm",
    SurfaceFinish: "Polished",
    WireSpacing: "2,3,4",
    Color: "Silver",
    Feature: "Rust Proof",
  },
  {
    img: turnbuckle,
    title: "Turnbuckle hook",
    desc: "High-strength stainless steel turnbuckle hook designed for precise tension adjustment in invisible grill installations. Ensures secure, stable fitting while maintaining an unobtrusive appearance. Ideal for both residential and commercial applications requiring durability and functionality.",
    alt: "Turnbuckle, hook",
    GradeField: "304/316",
    Technique: "customized",
    Material: "Stainless Steel",
    ProductType: "Invisible Grill Wire Roll Hook",
    UsageApplication: "For balcony and staircase",
    GrillDesign: "Modern",
    WireDiameter: "3 mm",
    SurfaceFinish: "Polished",
    WireSpacing: "2,3,4",
    Color: "Silver",
    Feature: "Rust Proof",
  },
  {
    img: Hooks2,
    title: "D-Shackle",
    desc: "Premium stainless steel D-shackle hook designed for secure and reliable attachment in invisible grill systems. Provides robust strength and stability while maintaining a sleek, unobtrusive look. Ideal for residential and commercial applications needing durable and efficient grill hardware.",
    alt: "D-Shackle",
    GradeField: "304/316",
    Technique: "customized",
    Material: "Stainless Steel",
    ProductType: "Invisible Grill Wire Roll Hook",
    UsageApplication: "For balcony and staircase",
    GrillDesign: "Modern",
    WireDiameter: "3 mm",
    SurfaceFinish: "Polished",
    WireSpacing: "2,3,4",
    Color: "Silver",
    Feature: "Rust Proof",
  },
  {
    img: BulletHook,
    title: "Moveble Bullet Fitting",
    desc: "Heavy-duty stainless steel bullet hook designed for secure and discreet attachment in invisible grill systems. Offers exceptional durability and strength, ensuring reliable performance while preserving an unobstructed view. Ideal for both residential and commercial grill installations.",
    alt: "Moveble Bullet Fitting",
    GradeField: "304/316",
    Technique: "customized",
    Material: "Stainless Steel",
    ProductType: "Invisible Grill Wire Roll Hook",
    UsageApplication: "For balcony and staircase",
    GrillDesign: "Modern",
    WireDiameter: "3 mm",
    SurfaceFinish: "Polished",
    WireSpacing: "2,3,4",
    Color: "Silver",
    Feature: "Rust Proof",
  },
];

export default InvisibleData;
